.editor-container__editor {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.editor-header {
    font-size: 24px;
    margin-bottom: 16px;
    color: #333;
}


.ck-editor {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    padding: 45px;
    
}


.ck.ck-button {
    border-radius: 4px;
}

.ck.ck-button.ck-on {
    background-color: #e7f1ff;
}


.ck-editor__editable.ck-rounded-corners {
    padding: 26px;  
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 300px;
}